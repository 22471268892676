.wrapper {
  display: flex !important;
  justify-content: center !important;
  z-index: 5 !important;
  position: absolute !important;
  width: 95vw;
  height: 90vh;
}

.modal-execution {
  position: relative !important;
  top: 15vh !important;
  width: 680px;
  height: 575px;
  box-shadow: 0 0 30px rgb(0, 0, 0);
  border-radius: 1.25rem !important;
}

.blur {
  filter: blur(3px);
}

.buy-sell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  margin-right: 50px;
}

.trans {
  transform: rotate(270deg);
}

.btn-show {
  margin-top: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.15) ;
  button {
    margin-top: 20px !important;
  }
}
.input-span select {
  margin-right: 20px;
  width: 200px;
}

.verticalLine {
  align-self: center;
}

.bc_icon {
  background-color: rgba(0, 0, 0, -0.97) !important;
}

.border-modal-execution {
  border-radius: 1.25rem !important;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.btn-field {
  background-color: #ecf5fd;
  color: #2c405a;
  font-weight: bold;
  border: 0.2em solid #2c405a;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-field:hover {
  background-color: #2c405a !important;
  color: white !important;
  font-weight: bold !important;
  border: 0.2em solid #2c405a !important;
}

.btn-field-active {
  background-color: #2c405a !important;
  color: white !important;
  font-weight: bold !important;
  border: 0.2em solid #2c405a !important;
}

.btn-field:focus {
  background-color: #2c405a !important;
}
