.wrapperClose {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: fixed;
  right: 9px;
  bottom: 35px;
  cursor: pointer;
  z-index: 99999999999;
  background-color: rgb(44, 64, 90);
  color: white;
}

.wrapperOpen {
  width: 450px;
  height: 675px;
  border-radius:  10px 10px 0 0;
  border: 1px solid rgba(128, 128, 128, 0.41);
  border-bottom: none;
  background-color: white;
  position: fixed;
  z-index: 9999999999;
  right: 9px;
  bottom: 65px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(128, 128, 128, 0.41);
    padding: 10px;
  }

    .btn {
      border: none;
      background: none;
      outline: none;
  }

  .content {
    padding: 10px;

    .msgBot {
      padding: 10px;
      color: rgb(44, 64, 90);
      background-color: rgba(234, 231, 231, 0.64);
      border-radius:  10px;
      margin-bottom: 10px;
      float: left;
      clear:both;
      animation: animationMsg 0.5s;
      hyphens: auto;
      white-space: pre-wrap;


      .svgIcon{
        margin-right: 10px
      }
    }

    .msgUser {
      padding: 10px;
      color: white;
      background-color: rgb(44, 64, 90);
      border-radius:  10px;
      margin-bottom: 10px;
      clear:both;
      float: right;
      animation: animationMsg 0.5s;
      hyphens: auto;

      .svgIcon{
        margin-right: 10px
      }
    }
  }
}

.suggestedQuestions {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;


  .msgButtonBot {
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px;
    color: rgb(44, 64, 90);
    background-color: rgba(234, 231, 231, 0.64);
    border-radius:  10px;
    margin-bottom: 10px;
    float: left;
    clear:both;
    border: 1px solid rgba(255, 255, 255, 0);
    animation: animationMsg 0.5s;
    hyphens: auto;
    cursor: pointer;
    text-align: start;
    outline: none;
    font-size: 0.7em;

    &:hover {
      background-color: rgba(234, 231, 231, 0.44);
      color: rgb(31, 46, 65);
      border: 1px solid rgb(31, 46, 65);
    }
  }
}

.wrapperInput {
  position: fixed;
  z-index: 99999999999;
  right: 9px;
  bottom: 15px;

  .pushMsg {
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    background: none;
    outline: none;
    z-index: 99999999999
  }

  .textMsg {
    position: relative;
    padding: 10px;
    width: 450px;
    height: 50px;
    border: 1px solid rgba(128, 128, 128, 0.41);
    outline: none;
    border-radius: 0 0 10px 10px ;
    padding-right: 40px;
    z-index: 99999999999;

    &:disabled {
      background-color: rgb(234, 231, 231);
      opacity: 1;
    }

    &::placeholder {
      color: rgb(44, 64, 90);
    }
  }
}






@keyframes animationMsg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}






