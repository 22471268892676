.info-item {
  line-height: 19px;
}

.info-item.default {
  font-size: 11px;
  color: rgb(141, 171, 196);
  font-weight: 500;
}

.info-item.dark {
  font-size: 13px;
  color: #2c405a !important;
  font-weight: 500;
}

.info-item-dark label {
  font-size: 13px;
  color: #2c405a !important;
  font-weight: 500;
}
