.telemetry-item {
  display: flex;
  align-items: baseline;
  border-radius: 25px;
  padding: 10px;
  position: relative;
  margin-bottom: 10px;
}

.telemetry-item__status {
  width: 20px;
  height: 20px;
  display: flex;
  align-self: center;
  border-radius: 50%;
}

.telemetry-item__status_active {
  background-color: lightgreen;
  box-shadow: 0 0 10px lightgreen;
}

.telemetry-item__status_disabled {
  background-color: lightgray;
  box-shadow: 0 0 10px lightgray;
}

.telemetry-item__status_inactive {
  background-color: #ffd3008f;
  box-shadow: 0 0 10px #ffd3008f;
}

.telemetry-item__status_not-responded {
  background-color: red;
  box-shadow: 0 0 10px red;
}

.telemetry-item__status-text {
  font-weight: 500;
  letter-spacing: 2px;
  margin-left: 5px;
}

.telemetry-item_not-responded {
  background-color: rgb(255, 218, 216);
}

.telemetry-item_inactive {
  background-color: lightgoldenrodyellow;

  &:hover {
    box-shadow: 0 0 10px 0 lightgoldenrodyellow;
  }
}

.telemetry-item.telemetry-item_not-responded:hover {
  box-shadow: 0 0 10px 0 rgb(255, 218, 216);
}

.telemetry-item_active {
  background-color: rgb(210, 255, 210);

  &:hover {
    box-shadow: 0 0 10px 0 rgb(210, 255, 210);
  }
}

.telemetry-item_disabled {
  background-color: rgba(0, 0, 0, 0.03);

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
  }
}

.telemetry-item__header {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.1;
  height: 60px;
}

.telemetry-item__footer {
  height: 24px;
  text-align: center;
}
