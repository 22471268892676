.quote-source {
  width: 455px;
  margin-right: 30px;
  transition: box-shadow 175ms ease;
}

.quote-source-model {
  width: 340px;
  margin-right: 10px;
  transition: box-shadow 175ms ease;
}

.table-modal {
  width: 900px;
  margin-right: 30px !important;
}

.quote-source-selected {
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
}

.color-ask {
  background-color: #ffe4ff;
}

.color-bid {
  background-color: #d7e4ff;
}

$best-price-border: 2px solid black;

.best-ask-row {
  td:nth-child(2), td:nth-child(3), td:nth-child(4) {
    border-top: $best-price-border;
  }

  td:nth-child(2) {
    border-left: $best-price-border;
  }

  td:nth-child(3), td:nth-child(4) {
    border-right: $best-price-border;
  }
}

.best-bid-row {
  td:nth-child(2), td:nth-child(3), td:nth-child(4) {
    border-bottom: $best-price-border !important;
  }

  td:nth-child(2) {
    border-left: $best-price-border;
  }

  td:nth-child(3), td:nth-child(5) {
    border-right: $best-price-border;
  }
}

.best-ask-row-modal {
  td:nth-child(1),  td:nth-child(3) {
    border-top: $best-price-border;
  }

  td:nth-child(1) {
    border-left: $best-price-border;
  }

   td:nth-child(3) {
    border-right: $best-price-border;
  }
}

.best-bid-row-modal {
  td:nth-child(1),  td:nth-child(3) {
    border-bottom: $best-price-border !important;
  }

  td:nth-child(1) {
    border-left: $best-price-border;
  }

   td:nth-child(3) {
    border-right: $best-price-border;
  }
}

/////////////////


.bestQ-ask-row {
  td:nth-child(2), td:nth-child(3) {
    border-top: $best-price-border;
  }

  td:nth-child(2) {
    border-left: $best-price-border;
  }

  td:nth-child(3) {
    border-right: $best-price-border;
  }
}

.bestQ-bid-row {
  td:nth-child(2), td:nth-child(3) {
    border-bottom: $best-price-border !important;
  }

  td:nth-child(2) {
    border-left: $best-price-border;
  }

  td:nth-child(3) {
    border-right: $best-price-border;
  }
}

